<template>
    <v-row>
        <v-col cols="12" class="d-flex align-start align-sm-center flex-sm-row flex-column">
            <div class="mr-sm-4">
                <div class="image-upload-display">
                    <v-img v-if="imgList.length <= 0" :src="require('@/assets/empty-image.svg')" :max-height="150"
                        :max-width="250"></v-img>
                    <template v-else>
                        <div v-for="(img, index) of imgList" :key="index">
                            <v-img class="preview-img" :src="getImage(img)" :max-height="150" :max-width="250"
                                @click="previewImg(index)"></v-img>
                            <v-btn depressed small text color="error" class="mt-1" @click="removeImage(index)"
                                :class="{ 'pointer-none': uploading }">刪除</v-btn>
                        </div>
                    </template>
                </div>
            </div>
            <v-file-input v-show="false" :id="fileInputKey" :accept="fileFormatStr" @change="getUploadFile($event)"
                :disabled="uploading"></v-file-input>
            <v-btn class="my-4" color="primary" depressed @click="_triggerUploadFile()" :loading="uploading"> 新增圖片 </v-btn>
        </v-col>

    </v-row>
</template>
  
<script>

export default {
    name: 'ImageUploader',
    props: {
        fileInputKey: {
            type: String,
            default: 'upload_file',
            required: true,
        },
        imgList: {
            type: Array,
            default: () => [],
            require: true,
        },
        acceptFormat: {
            type: String,
            default: 'image',
            require: true,
        },
        singleImage: {
            type: Boolean,
            default: true,
            require: true,
        },
        dispatchUpdateOnChange: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        uploading() {
            return this.$store.getters.isUploading
        },
        fileFormatStr() {
            return this.$common.getAcceptFileFormat(this.acceptFormat).formatStr;
        },
        fileFormatArr() {
            return this.$common.getAcceptFileFormat(this.acceptFormat).formatArr;
        }
    },
    data: () => ({}),
    methods: {
        _triggerUploadFile() {
            if (this.$validate.DataValid(this.fileInputKey) && document.getElementById(this.fileInputKey)) {
                document.getElementById(this.fileInputKey).click();
            }
        },
        async getUploadFile(file) {
            if (file !== undefined && file !== null) {
                const check = this.$validate.validateFileInput(file, this.fileFormatArr);
                if (this.$validate.DataValid(check)) {
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: check,
                        type: 'error',
                        refresh: false,
                        redirect: '',
                    })
                } else {
                    this.$store.dispatch('setUploadLoading', true);

                    const reader = new FileReader();
                    reader.onload = async (event) => {
                        const fileType = file.type.split('/')[1];
                        const payload = {
                            upload_data: event.target.result,
                            upload_file_type: fileType
                        }

                        try {
                            const data = await this.$Fetcher.UploadFile(payload);
                            this.$store.dispatch('toggleAlertMessage', {
                                show: true,
                                message: '上傳成功',
                                type: 'success',
                                refresh: false,
                                redirect: '',
                            })

                            let fileList = [];
                            if (!this.singleImage) {
                                fileList = [...this.imgList];
                            }
                            fileList.push(data);
                            this.$emit('update:imgList', fileList);

                            if (this.dispatchUpdateOnChange === true) {
                                this.$store.dispatch('setDataIsUpdated', true);
                            }
                        } catch(err) {
                            this.$common.error(err);
                            this.$store.dispatch('toggleAlertMessage', {
                                show: true,
                                message: '上傳失敗',
                                type: 'error',
                                refresh: false,
                                redirect: '',
                            })
                        } finally {
                            this.$store.dispatch('setUploadLoading', false);
                        }
                    }
                    reader.readAsDataURL(file);
                }
            }
        },
        getImage(img) {
            if (img.indexOf('pdf') !== -1) {
                return require('@/assets/file-pdf-box.svg')
            } else if (img.indexOf('mp4') !== -1 || img.indexOf('webm') !== -1) {
                return require('@/assets/video.svg')
            }

            return this.$mediaPath + img;
        },

        previewImg(index) {
            if (this.$validate.DataValid(this.imgList[index])) {
                window.open(this.$mediaPath + this.imgList[index])
            } else {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '檔案不存在',
                    type: 'error',
                    refresh: false,
                    redirect: '',
                })
            }
        },
        removeImage(index) {
            const fileList = this.$common.duplicateData(this.imgList);
            fileList.splice(index, 1);
            this.$emit('update:imgList', fileList);

            if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true);
            }
        },
    },
}
</script>
  
<style lang="scss" scoped>
.preview-img {
    &:hover {
        cursor: pointer;
    }
}

.image-upload-display {
    display: flex;
    overflow-x: auto;
    align-items: center;

    &>div:not(:first-child) {
        margin-left: 20px;
    }
}

.no-text-upload-btn.v-btn {
    width: 100% !important;
    height: 150px !important;
    border: 2px solid rgba(94, 86, 105, 0.141);
    min-width: unset !important;
}
</style>
  