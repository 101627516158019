<template>
  <DialogLayout
    :openDialog="open"
    :maxWidth="maxWidth"
    :title="title"
    btnAlign="center"
    enableCancel
    :enableClose="false"
    cardContentClass="px-3 pt-8 pb-4"
    @close="_dialogAction"
  >
    <slot v-if="!isDeleteDialog" class="colorBlack--text"></slot>
    <div v-else class="colorBlack--text">{{ deleteDescription }}</div>
    <v-checkbox v-if="enableShowRemindCheckbox" hide-details v-model="disableRemind" label="不再顯示" color="primary" class="colorBlack--text"></v-checkbox>
  </DialogLayout>
</template>
  
<script>
import DialogLayout from '@/components/layout/DialogLayout.vue';
export default {
  name: 'ConfirmDialog',
  components: {
    DialogLayout
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '是否刪除此項目？',
    },
    maxWidth: {
      type: Number,
      default: 500,
      required: false,
    },
    isDeleteDialog: {
      type: Boolean,
      required: false,
      default: true,
    },
    deleteDescription: {
      type: String,
      required: false,
      default: '是否確定要刪除此項目？此動作無法復原。',
    },
    enableShowRemindCheckbox: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    open: false,
    resolvePromise: undefined,
    rejectPromise: undefined,
    disableRemind: false,
  }),
  methods: {
    show() {
      if (!this.disableRemind) {
        this.open = true;
      }
     
      return new Promise((resolve, reject) => {
          this.resolvePromise = resolve;
          this.rejectPromise = reject;

          if (this.disableRemind) {
            this.resolvePromise(true);
          }
      })
    },


    _dialogAction(action) {
      this.open = false;
      if (action === true) {
        this.resolvePromise(true);
      } else {
        this.disableRemind = false;
        this.resolvePromise(false);
      }
    },
  },
}
</script>


  