<template>
    <div class="custom-input">
        <label v-if="$validate.DataValid(label)" class="input-label">{{label}}</label>
        <v-select 
            :value="fieldValue"
            outlined
            single-line
            :rules="rules"
            :readonly="isReadOnly"
            :placeholder="placeholder"
            :no-data-text="noDataText"
            :multiple="isMultiple"
            item-text="text"
            item-value="value"
            :items="options"
            @blur="updateValueOnBlur()"
            @change="updateValueOnChange($event)"
            :hide-details="hideDetails"
            :disabled="disabled"
            :dense="dense"
            :loading="isLoading"
        >
            <template v-slot:append>
              <v-icon class="fi fi-rr-angle-small-down" size="18"></v-icon>
            </template>
        </v-select>
    </div>
</template>

<script>
export default {
    name: 'FormSelect',
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        fieldValue: {
            type: String | Array,
            required: true,
            default: ''
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        noDataText: {
            type: String,
            required: false,
            default: '沒有選項'
        },
        isMultiple: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        dispatchUpdateOnChange: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        isReadOnly() {
            return this.readonly || this.$store.getters.isLoading
        },
        rules() {
            return [
                v => {
                    return this.required ? (this.$validate.required(v) || '此位置不能留空') : true
                },
            ]
        },
    },
    data: () => ({}),
    methods: {
        updateValueOnBlur() {
            if (this.isMultiple) {
                this.$emit('changed', this.fieldValue);
            }

        }, 
        updateValueOnChange(value) {
            this.$emit('update:fieldValue', value);
            if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true);
            }
            
            if (!this.isMultiple) {
                this.$emit('changed', value);
            }
        }
    }
}
</script>